import React from 'react';
import { navigate } from 'gatsby';
import isStrongPassword from 'validator/lib/isStrongPassword';

import { withFirebase } from '../Firebase';

import Row from '../Container/row';
import Col from '../Container/column';
import Form from '../Form';
import Button from '../Button';
import { passwordStrength } from '../PasswordChange/errors';

import alertStyle from '../Alert/style';
// import { loginForm } from './style';

import loadComponents from '../Loadable';

// const Button = loadComponents('button');
const Alert = loadComponents('alert');
// const Form = loadComponents('form');

class Authorization extends React.Component {
  _initFirebase = false;

  constructor(props) {
    super(props);
    this.state = {
      success: false,
      error: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this._initFirebase = false;
  }

  firebaseInit = () => {
    const { firebase } = this.props;
    if (firebase && !this._initFirebase) {
      this._initFirebase = true;
      const { mode, oobCode } = this.props;
      switch (mode) {
        case 'verifyEmail':
          firebase
            .verifyEmail(oobCode)
            .then(() => {
              this.setState({ success: true }, () => {
                firebase.doSignOut();
              });
            })
            .catch(error => {
              this.setState({ error });
            });
          break;
        case 'resetPassword':
          firebase.verifyPasswordReset(oobCode).catch(error => {
            this.setState({ error });
          });
          break;
        default:
          navigate('/');
      }
    }
  };

  handleSubmit = ({ data }) => {
    const { oobCode, firebase } = this.props;
    const { password } = data;

    const checkPassword = isStrongPassword(password);

    if (!checkPassword) {
      this.setState({ error: { message: passwordStrength } });
    } else {
      firebase
        .confirmPassword(oobCode, password)
        .then(() => {
          if (this._initFirebase) this.setState({ success: true });
        })
        .catch(error => {
          if (this._initFirebase) this.setState({ error });
        });
    }
  };

  render() {
    const { mode } = this.props;
    const { success, error } = this.state;
    return (
      <Row>
        <Col xxl={{ width: `60%` }}>
          {error && (
            <Alert style={alertStyle} error>
              {error.message}
            </Alert>
          )}
          {mode === 'verifyEmail' && <VerifySuccess success={success} />}
          {mode === 'resetPassword' && (
            <>
              {success ? (
                <PasswordSuccess />
              ) : (
                <div style={{ width: `500px`, maxWidth: `100%` }}>
                  <Form
                    name="oob"
                    fields={passwordFields}
                    action={this.handleSubmit}
                    // debug
                  />
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    );
  }
}

export default withFirebase(Authorization);

const passwordFields = [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'password',
        width: '100%',
        name: 'password',
        label: 'New Password',
        required: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Change Password',
      },
    ],
  },
];

const VerifySuccess = () => (
  <>
    <p>
      Your email address has now been verified. Use the button below to login to
      your account.
    </p>
    <Button
      to="/account/login/"
      primary
      style={{ width: `300px`, maxWidth: `100%` }}
    >
      Login
    </Button>
  </>
);

const PasswordSuccess = () => (
  <>
    <p>
      You have successfully reset your password. Use the button below to login
      to your account.
    </p>
    <Button
      to="/account/login/"
      primary
      style={{ width: `300px`, maxWidth: `100%` }}
    >
      Login
    </Button>
  </>
);
