import React from 'react';
import queryString from 'query-string';

import Layout from '../../layouts';
import Container from '../../components/Container';
import PageTitle from '../../components/Subpage/title';
import Authorization from '../../components/Account/oob';
import Button from '../../components/Button';

// import loadComponents from '../../components/Loadable';

// const Button = loadComponents('button');
// const Authorization = loadComponents('account-auth');

export default class AuthPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this._isMounted = true;
    this.initAuth();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  pageTitle = () => {
    const { mode } = this.state;
    if (mode === 'verifyEmail') {
      return 'Verify your email address';
    }
    if (mode === 'resetPassword') {
      return 'Reset your password';
    }
    return 'Unauthorized Access';
  };

  initAuth() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    if (this._isMounted) {
      this.setState({ ...query });
    }
  }

  render() {
    const { mode } = this.state;
    const slug = '/account/auth/';
    return (
      <Layout title={this.pageTitle()} location={slug} seo={null}>
        <PageTitle title={this.pageTitle()} />
        <Container>
          {mode ? (
            <Authorization {...this.state} />
          ) : (
            <Button to="/" primary style={{ width: `300px`, maxWidth: `100%` }}>
              Return to Homepage
            </Button>
          )}
        </Container>
      </Layout>
    );
  }
}
