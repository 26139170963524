import React from 'react';

import Inner from './inner';
import Link from '../Links';

import './button.css';

// import loadComponents from '../Loadable';

// const Link = loadComponents('link');

export default function Button(props) {
  const {
    to,
    children,
    primary,
    secondary,
    dark,
    ref,
    style,
    className,
    ...other
  } = props;

  const innerProps = {
    className,
    primary,
    secondary,
    dark,
    style,
    ref,
  };
  return (
    <Link to={to} className="btnOuter" {...other}>
      <Inner {...innerProps}>{children}</Inner>
    </Link>
  );
}
