import React, { useState, useEffect } from 'react';

import loadLibrary from '../Loadable/library';

const Link = loadLibrary('gatsby-link');

export default function Links({
  children,
  content,
  label,
  to,
  style,
  ...other
}) {
  const [currentUrl, setCurrentUrl] = useState('');
  useEffect(() => {
    function initLinks() {
      const url = typeof window !== 'undefined' && window.location.pathname;
      setCurrentUrl(url);
    }
    initLinks();
  }, []);

  const linkContent = content
    ? { dangerouslySetInnerHTML: { __html: content } }
    : { children };

  const linkLabel =
    label ||
    content ||
    (typeof children === 'object' ? children.props.children : children);

  const sharedProps = {
    to: to.replace('/./', '/'),
    state: { prevPage: currentUrl },
    activeClassName: 'active',
    itemProp: 'url',
    style: style || undefined,
    'aria-label': linkLabel,
  };

  return <Link {...linkContent} {...sharedProps} {...other} />;
}
