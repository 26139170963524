import React from 'react';

import { buttonStyles } from './style';
import { MainColour, TitleColour } from '../../utils/variables';

export default function ButtonInner(props) {
  const { children, primary, secondary, dark, ref, style, className } = props;

  const primaryStyle = primary && {
    backgroundColor: dark ? TitleColour : MainColour,
    color: `#fff`,
  };

  const secondaryStyle = secondary && {
    border: `2px solid ${dark ? TitleColour : `#fff`}`,
    color: dark ? TitleColour : `#fff`,
  };

  return (
    <div
      className={`btn${className ? ` ${className}` : ``}`}
      style={{
        ...buttonStyles,
        ...style,
        ...primaryStyle,
        ...secondaryStyle,
      }}
      ref={ref}
    >
      <span
        style={{
          display: `inline-flex`,
          alignItems: `center`,
        }}
      >
        {children}
      </span>
    </div>
  );
}
